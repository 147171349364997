import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AnalyticsChart } from '../../../models/AnalyticsChart';

interface Props {
  item: AnalyticsChart;
  onClose: () => void;
}

export const AnalyticsChartDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Tipo de Análise Gráfica
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Título:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Analises:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                {item.analytics_types?.map((d) => (
                  <li>
                    {d.code} - {d.description}
                  </li>
                ))}
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Usa Filtros:
            </Typography>
            <>{item.use_filters ? <Alert severity="success">Sim</Alert> : <Alert severity="error">Não</Alert>}</>
          </Grid>
          <Grid item xs={12} md={12} />
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Período Atual:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.current_period == 'ANNUAL' ? 'Anual' : item.current_period == 'MONTHLY' ? 'Mensal' : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Período Retroativo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.retroactive_period == 'ANNUAL' ? 'Anual' : item.retroactive_period == 'MONTHLY' ? 'Mensal' : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Quantidade Retroativa:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.retroactive_quantity}
              {item.retroactive_period == 'ANNUAL' ? ' Anos' : item.retroactive_period == 'MONTHLY' ? ' Meses' : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Formulas:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                {item.formulas?.map((d) => (
                  <li>{d.title}</li>
                ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
