import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { AnalyticsAccounts } from '../../../models/AnalyticsAccounts';
import { Company } from '../../../models/Company';
import { ManualInput } from '../../../models/ManualInput';
import { AnalyticsAccountService } from '../../../services/AnalyticsAccount.service';
import { ManualInputsService } from '../../../services/ManualInput.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../store/store';
import { ManualInputScheme } from '../../../utils/forms/validations/formValidations';
import { formatDateToApi, formatSimpleDate, setInputErrorsFromApi } from '../../../utils/utils';
import { useManualInputsContext } from '../context/ManualInputsContext';

interface Props {
  editItem?: ManualInput;
  onClose: () => void;
}

export const ManualInputFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchManualInputs, handleSetSaved } = useManualInputsContext();
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [analyticsAccounts, setAnalyticsAccounts] = useState<SelectOption[]>([]);
  const [companiesGroup, setCompaniesGroup] = useState<Company[]>([]);
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [useCenterCost, setUseCenterCost] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [selectedDebitAccount, setSelectedDebitAccount] = useState<SelectOption>({} as SelectOption);
  const [selectedCreditAccount, setSelectedCreditAccount] = useState<SelectOption>({} as SelectOption);
  const [loading, setLoading] = useState<boolean>(true);

  const { companiesData } = useSelector((state: RootState) => state.companiesContext);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors }
  } = useForm<ManualInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ManualInputScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          value: editItem.value?.replace('.', ','),
          entry_date: formatSimpleDate(editItem.entry_date)
        }
      : {
          history: '',
          value: '',
          debit_cost_center: '',
          credit_cost_center: '',
          entry_date: '01'
        }
  });

  const createOrUpdateManualInput: SubmitHandler<ManualInput> = async (data: ManualInput) => {
    handleSetSaved(true);
    data = {
      ...data,
      company: Number(selectedCompany),
      debit_account: selectedDebitAccount?.id,
      credit_account: selectedCreditAccount?.id,
      entry_date: formatDateToApi(data.entry_date),
      value: data.value.replace('R$ ', '').replace('.', '').replace(',', '.')
    };
    dispatch(showSpinner());
    try {
      editItem?.id ? await ManualInputsService.update(data) : await ManualInputsService.create(data);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Lançamento Manual atualizado com sucesso!' : 'Lançamento Manual criado com sucesso!',
          severity: 'success'
        })
      );
      fetchManualInputs();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleCompanyChange = (value: any) => {
    const company_id = value.target.value;
    if (value) {
      setIsCompanySelected(true);
    } else {
      setIsCompanySelected(false);
    }

    const company = companiesGroup.find((company) => company.id === company_id);
    setSelectedCompany(company_id);
    if (company?.group.use_cost_center) {
      setUseCenterCost(true);
    }
  };

  const fetchAccounts = async (watchAccountSearch?: string) => {
    try {
      const params = selectedCompany ? { company: selectedCompany, is_analytical: 1 } : { is_analytical: 1 };
      const response = await AnalyticsAccountService.get({ ...params, search: watchAccountSearch });
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setAnalyticsAccounts(
          dataAccounts.map((account: AnalyticsAccounts) => ({
            id: account.id,
            name: `${account.code} - ${account.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };
  const handleSelectDebitAccount = (value: SelectOption) => {
    setSelectedDebitAccount(value);
  };
  const handleSelectCreditAccount = (value: SelectOption) => {
    setSelectedCreditAccount(value);
  };
  useEffect(() => {
    (async () => {
      if (editItem && editItem.company) {
        if (editItem.company?.use_cost_center) {
          setUseCenterCost(true);
        }
        setSelectedCompany(editItem.company.id);
        setIsCompanySelected(true);
      }
      if (editItem?.debit_account) {
        const account = editItem.debit_account;

        setSelectedDebitAccount({
          id: account?.id,
          name: `${account?.code} - ${account?.description}`
        });
      }
      if (editItem?.credit_account) {
        const account = editItem.credit_account;
        setSelectedCreditAccount({
          id: account?.id,
          name: `${account?.code} - ${account?.description}`
        });
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (companiesData.length > 0) {
      setCompaniesGroup(companiesData);
      setCompanies(
        companiesData
          .filter((company: Company) => company.company_id)
          .map((company: Company) => ({
            id: company.company_id,
            name: company.company_name
          }))
      );
    } else {
      dispatch(
        showSnackbarAlert({
          title: 'Alerta',
          message: 'Selecione a empresa no menu superior para criar um lançamento manual',
          severity: 'warning'
        })
      );
    }
  }, [companiesData]);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Lançamento Manual
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateManualInput)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <Select {...field} style={{ width: '100%', height: 35 }} onChange={handleCompanyChange} value={selectedCompany}>
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="entry_date">Data</InputLabel>
              <ControlledTextInput
                name="entry_date"
                placeholder="Data"
                control={control}
                fullWidth
                mask="99/99/9999"
                errorMessage={errors.entry_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="history">Histórico</InputLabel>
              <ControlledTextInput
                name="history"
                placeholder="Histórico"
                control={control}
                errorMessage={errors.history?.message?.toString()}
              />
            </Grid>
            {useCenterCost && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="debit_cost_center">Centro de Custo Débito</InputLabel>
                  <ControlledTextInput
                    name="debit_cost_center"
                    placeholder="Centro de Custo Débito"
                    control={control}
                    errorMessage={errors.debit_cost_center?.message?.toString()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="credit_cost_center">Centro de Custo Crédito</InputLabel>
                  <ControlledTextInput
                    name="credit_cost_center"
                    placeholder="Centro de Custo Crédito"
                    control={control}
                    errorMessage={errors.credit_cost_center?.message?.toString()}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="debit_account">Conta Débito</InputLabel>
              <AutocompleteSearch
                value={selectedDebitAccount}
                defaultValue={
                  editItem?.debit_account?.id
                    ? {
                        id: editItem.debit_account?.id,
                        name: `${editItem.debit_account.code} - ${editItem.debit_account.description}`
                      }
                    : null
                }
                onSearch={fetchAccounts}
                selectOptions={analyticsAccounts}
                onSelect={(event, value: SelectOption) => handleSelectDebitAccount(value)}
                errorMessage={errors.debit_account?.message}
                getOptionLabel={(option) => option.name}
                disabled={!isCompanySelected}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="credit_account">Conta Crédito</InputLabel>
              <AutocompleteSearch
                value={selectedDebitAccount}
                defaultValue={
                  editItem?.credit_account?.id
                    ? {
                        id: editItem.credit_account.id,
                        name: `${editItem.credit_account.code} - ${editItem.credit_account.description}`
                      }
                    : null
                }
                onSearch={fetchAccounts}
                selectOptions={analyticsAccounts}
                onSelect={(event, value: SelectOption) => handleSelectCreditAccount(value)}
                errorMessage={errors.credit_account?.message}
                getOptionLabel={(option) => option.name}
                disabled={!isCompanySelected}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor</InputLabel>
              <ControlledTextInput
                name="value"
                placeholder="Valor"
                control={control}
                errorMessage={errors.value?.message?.toString()}
                currency={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
