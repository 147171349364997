import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { AnalyticsChart } from '../../../models/AnalyticsChart';
import { AnalyticsChartDetailsDialog } from '../AnalyticsChartDetailsDialog';
import { AnalyticsChartFilterDialog } from '../AnalyticsChartFilterDialog';
import { AnalyticsChartFormDialog } from '../AnalyticsChartFormDialog';
import { AnalyticsChartTable } from '../AnalyticsChartTable';
import { useAnalyticsChartContext } from '../context/AnalyticsChartContext';
import { ButtonsContainer } from './styles';

export const AnalyticsChartPage = () => {
  const { permissions } = useCrudPermissions({ submodule: 'ANALYTICS' });

  const [showFilter, setShowFilter] = useState(false);
  const [editItem, setEditItem] = useState<AnalyticsChart | undefined>(undefined);
  const [editAnalytics, setEditAnalytics] = useState<AnalyticsChart | undefined>(undefined);
  const [viewItem, setViewItem] = useState<AnalyticsChart | undefined>(undefined);
  const [newItem, setNewItem] = useState(false);
  const [numFilters, setNumFilters] = useState<number>(0);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useAnalyticsChartContext();

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setEditAnalytics(undefined);
    setViewItem(undefined);
  };

  const handleViewItem = (item: AnalyticsChart) => {
    setViewItem(item);
  };

  const handleEditItem = (item: AnalyticsChart) => {
    setEditItem(item);
  };

  const handleEditAnalytics = (item: AnalyticsChart) => {
    setEditAnalytics(item);
  };

  const handleAddAnalyticType = () => {
    setNewItem(true);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Buscar Tipos de Analises Gráficas"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros {numFilters > 0 && `(${numFilters})`}
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Tipo de Analise Gráfica">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddAnalyticType}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <AnalyticsChartTable
        onView={handleViewItem}
        onEdit={handleEditItem}
        onEditAnalytics={handleEditAnalytics}
        permissions={permissions}
      />
      {viewItem && <AnalyticsChartDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {showFilter && <AnalyticsChartFilterDialog onClose={handleCloseDialogs} setNumFilters={setNumFilters} />}
      {(newItem || editItem) && <AnalyticsChartFormDialog editItem={editItem} onClose={handleCloseDialogs} setEditItem={handleEditItem} />}
    </>
  );
};
