import { capitalize, indexOf } from 'lodash';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITableColumn } from '../..';
import { IAnalyticsDRE, IAnalyticsDREFilter } from '../../../../../../models/AnalyticsDRE';
import { CompanyGroup } from '../../../../../../models/CompanyGroup';
import { AnalysisDREService } from '../../../../../../services/AnalysisDRE.service';
import { CompaniesService } from '../../../../../../services/Companies.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../../store/store';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { formatColumnNameIntoDate } from '../../../../../../utils/utils';
import { ManualInputsModal } from '../../../../components/ManualInputsModal';
import { ChartModal } from '../ChartModal';
import { DetailsModal } from '../DetailsModal';
import { ManualInputModal } from '../ManualInputModal';
import { MinimizedModalBar } from '../MinimizedModalBar';

// Definindo a interface para o estado de abertura dos níveis
interface OpenLevels {
  [level: number]: boolean;
}

// Definindo a interface para o contexto
interface ContextData {
  openLevels: OpenLevels;
  toggleLevel: (level: number) => void;
  handleOpenNewDetailsModal: (data: any) => void;
  handleOpenNewManualInputsModal: (data: any) => void;
  handleNewManualInput: (data: any) => void;
  filters: IAnalyticsDREFilter[];
  filterUseBudget: boolean;
  data: IAnalyticsDRE;
  companyGroup: CompanyGroup;
  columns: ITableColumn[];
  toggleColumnVisibility: (columnId: string) => void;
  toggleModalFullscreen?: (modal: IAnalysisDreModal) => void;
  handleSetFilters: (filters: IAnalyticsDREFilter[]) => void;
  handleOpenChart: () => void;
  handleUseBudget?: (value: boolean) => void;
  handleShowInputsManual?: (value: boolean) => void;
  showInputsManual: boolean;
  refresh: boolean;
  handleSetRefresh: (value: boolean) => void;
}

export interface IAnalysisDreModal {
  title: string;
  visible: boolean;
  type: 'details' | 'chart' | 'manual_inputs';
  data: any;
  fullscreen?: boolean;
}

// Criação do Context
const AnalyticsDRETableContext = createContext<ContextData | undefined>(undefined);

// Definindo a interface para o provider
interface ProviderProps {
  children: ReactNode;
}

// Componente Provider
export function AnalyticsDREProvider({ children }: ProviderProps) {
  const dispatch = useDispatch();

  const [modalList, setModalList] = useState<any[]>([]);
  const [manualInputModal, setManualInputModal] = useState<any | null>(null);
  const [filters, setFilters] = useState<IAnalyticsDREFilter[]>({} as IAnalyticsDREFilter[]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [data, setData] = useState<IAnalyticsDRE>({} as IAnalyticsDRE);
  const [companyGroup, setCompanyGroup] = useState<CompanyGroup>({} as CompanyGroup);
  const [columns, setColumns] = useState<ITableColumn[]>([]);
  const [showInputsManual, setShowInputsManual] = useState<boolean>(true);
  const [filterUseBudget, setFilterUseBudget] = useState(false);

  const { companiesIds: companiesContext } = useSelector((state: RootState) => state.companiesContext);

  const VISIBLE_MODAL = modalList.filter((modal) => modal.visible);
  const MINIMIZED_MODALS = modalList.filter((modal) => !modal.visible);

  const fetchCompanyGroup = async () => {
    try {
      const response = await CompaniesService.getOne(companiesContext[0]);
      if (response && checkResponseStatus(response)) {
        setCompanyGroup(response?.data?.group);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleUseBudget = (value: boolean) => {
    setFilterUseBudget(value);
  };

  const handleShowInputsManual = (value: boolean) => {
    setShowInputsManual(value);
  };

  const [openLevels, setOpenLevels] = useState<OpenLevels>({
    1: true,
    2: false,
    3: false,
    4: false
  });

  const toggleLevel = (level: number) => {
    setOpenLevels((prevLevels) => ({
      ...prevLevels,
      [level]: !prevLevels[level]
    }));
  };

  const fetchChartData = async () => {
    dispatch(showSpinner());

    try {
      const response = await AnalysisDREService.getChartData(filters);
      if (response && checkResponseStatus(response)) {
        return response;
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOpenChart = async () => {
    const data = await fetchChartData();

    //adicionar um novo modal a lista
    const newModal = {
      title: 'Gráfico',
      visible: true,
      fullscreen: false,
      type: 'chart',
      data: data
    };

    setModalList([...modalList, newModal]);
  };

  const handleOpenNewDetailsModal = (data: any) => {
    //adicionar um novo modal a lista
    const newModal = {
      title: formatColumnNameIntoDate(data.key) + ' - ' + capitalize(data.row.description) + ' - ' + data.columnLabel,
      visible: true,
      type: 'details',
      data: data
    };
    setModalList([...modalList, newModal]);
  };

  const handleOpenNewManualInputsModal = (data: any) => {
    const newModal = {
      title: formatColumnNameIntoDate(data.key) + ' - ' + capitalize(data.row.description) + ' - ' + data.columnLabel,
      visible: true,
      fullScreen: false,
      type: 'manual_inputs',
      data: data
    };

    setModalList([...modalList, newModal]);
  };

  const handleCloseModal = (modal: any) => {
    const index = indexOf(modalList, modal);
    if (index > -1) {
      modalList.splice(index, 1);
    }
    setModalList([...modalList]);
    if (modal.type == 'manual_inputs' && refresh) {
      fetchData();
    }
  };

  const handleDisplayModal = (modal?: any) => {
    //abrir o modal clicado
    const index = indexOf(modalList, modal);
    let newList = modalList.map((modal, i) => {
      if (i === index) {
        modal.visible = true;
      } else {
        modal.visible = false;
      }
      return modal;
    });
    setModalList([...newList]);
  };

  const handleMinimizeAll = () => {
    let newList = modalList.map((modal) => {
      modal.visible = false;
      return modal;
    });
    setModalList([...newList]);
    if (refresh) {
      fetchData();
    }
    return;
  };

  const handleNewManualInput = (data: any) => {
    setManualInputModal(data);
  };

  const handleCloseManualInput = () => {
    setManualInputModal(undefined);
  };

  const handleSetRefresh = (value: boolean) => {
    setRefresh(value);
  };

  const toggleColumnVisibility = (columnId: string) => {
    setColumns((prevColumns) => prevColumns.map((column) => (column.id === columnId ? { ...column, visible: !column.visible } : column)));
  };

  const toggleModalFullscreen = (modal: IAnalysisDreModal) => {
    const index = indexOf(modalList, modal);
    if (index > -1) {
      modalList[index].fullscreen = !modalList[index].fullscreen;
    }
    setModalList([...modalList]);
  };

  const handleSetFilters = (filters: IAnalyticsDREFilter[]) => {
    setFilters(filters);
  };

  const createTableColumns = (data: any) => {
    if (!data.metadata.columns) {
      dispatch(
        showSnackbarAlert({
          title: 'Ocorreu um erro',
          message: 'Não foi possível carregar os dados da análise de balanço patrimonial',
          severity: 'error'
        })
      );
      return;
    }

    const newColumns: Array<any> = [];
    const insertedYears: Set<string> = new Set();

    data.metadata.columns.forEach((key: string, index: number) => {
      const year = key.substring(0, 4) || '';
      newColumns.push({
        id: key,
        name: formatColumnNameIntoDate(key),
        visible: true,
        type: 'totals',
        index: newColumns.length,
        year: year
      });

      const isLastColumn = index === data.metadata.columns.length - 1;
      const nextYear = !isLastColumn ? data.metadata.columns[index + 1].substring(0, 4) : null;
      const isEndOfYear = nextYear !== year;

      if (!insertedYears.has(year) && data.metadata.years.includes(year) && (isEndOfYear || isLastColumn)) {
        newColumns.push({
          id: year,
          name: 'Média ' + year,
          type: 'average',
          visible: true,
          index: newColumns.length,
          year: year
        });
        insertedYears.add(year);
      }
    });

    setColumns(newColumns);
  };

  const fetchData = async () => {
    dispatch(showSpinner());

    try {
      const response = await AnalysisDREService.post(filters);
      if (response && checkResponseStatus(response)) {
        setData(response?.data);
        createTableColumns(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  //ouvir as alteracoes do redux e fazer a busca novamente com as novas empresas.
  useEffect(() => {
    if (companiesContext && companiesContext.length > 0 && Object.entries(filters).length > 0) {
      fetchData();
    }
    fetchCompanyGroup();
  }, [companiesContext, filters]);

  return (
    <AnalyticsDRETableContext.Provider
      value={{
        openLevels,
        toggleLevel,
        handleOpenNewDetailsModal,
        handleOpenNewManualInputsModal,
        handleNewManualInput,
        filters,
        filterUseBudget,
        data,
        companyGroup,
        columns,
        toggleColumnVisibility,
        handleOpenChart,
        handleSetFilters,
        handleUseBudget,
        handleShowInputsManual,
        showInputsManual,
        refresh,
        handleSetRefresh
      }}
    >
      <ManualInputModal modalData={manualInputModal} onClose={handleCloseManualInput} />
      {VISIBLE_MODAL?.map((modal) => {
        return modal.type === 'details' ? (
          <DetailsModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
          />
        ) : modal.type === 'manual_inputs' ? (
          <ManualInputsModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
            handleSetRefresh={(value) => handleSetRefresh(value)}
          />
        ) : (
          <ChartModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
          />
        );
      })}
      <MinimizedModalBar
        modalList={MINIMIZED_MODALS}
        onDisplayModal={(modal) => handleDisplayModal(modal)}
        onCloseModal={(modal) => handleCloseModal(modal)}
      />
      {children}
    </AnalyticsDRETableContext.Provider>
  );
}

// Hook personalizado para usar o Level Context
export function useAnalyticsDRETableContext(): ContextData {
  const context = useContext(AnalyticsDRETableContext);
  if (!context) {
    throw new Error('useAnalyticsDRETableContext must be used within a AnalyticsDREProvider');
  }
  return context;
}
