import { Checkbox, DialogContent, Divider, FormControlLabel, Popover, Stack, Typography } from '@mui/material';
import { useAnalyticsDRETableContext } from '../../context/TableContext';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const ColumnsPopover = ({ anchorEl, onClose }: Props) => {
  const { columns, toggleColumnVisibility, showInputsManual, handleShowInputsManual } = useAnalyticsDRETableContext();

  let columnsByYear = columns?.reduce((acc, column) => {
    if (!acc[column.year]) {
      acc[column.year] = [];
    }
    acc[column.year].push(column);
    return acc;
  }, {} as any);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{ mt: 2 }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h5">Valores</Typography>
          <Stack spacing={2}>
            <FormControlLabel
              control={<Checkbox checked={showInputsManual} onChange={() => handleShowInputsManual(!showInputsManual)} />}
              label={'Lançamentos Manuais'}
              key={`manual-inputs`}
            />
          </Stack>
          <Divider />
          <Typography variant="h5">Períodos</Typography>
          {Object.keys(columnsByYear)?.map((key: any, index: number) => {
            let columns = columnsByYear[key];
            return (
              <Stack spacing={2} key={index}>
                {columns?.map((column: any, index: number) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox checked={column.visible} onChange={() => toggleColumnVisibility(column.id)} />}
                      label={column.name}
                      key={`${column.id}-${index}`}
                    />
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
    </Popover>
  );
};
