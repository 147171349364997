import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box, Button, DialogContent, Icon, Popover, Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCrudPermissions } from '../../../../../../hooks/useCrudPermissions';
import { useAnalyticsFCTableContext } from '../context/TableContext';

interface ITableRowProps {
  row: any;
  level?: number;
  analytic?: number | null;
}

export const TableRowRecursive = ({ row, level = 1, analytic = null }: ITableRowProps) => {
  const theme = useTheme();
  const { permissions: permissions_manual_input } = useCrudPermissions({ submodule: 'MANUAL_ACCOUNTING_ENTRY' });
  const [rightClickAnchorEl, setRightClickAnchorEl] = useState<null | HTMLElement>(null);
  const [righClickRow, setRightClickRow] = useState<any>({});

  const [rowOpen, setRowOpen] = useState<boolean>(false);
  const { openLevels, handleOpenNewDetailsModal, handleNewManualInput, handleOpenNewManualInputsModal, columns, showInputsManual } =
    useAnalyticsFCTableContext();
  const levelOpen = openLevels[level + 1];

  const paddingValue = `${(row.level - 1) * 40 || (level - 1) * 40}px`;
  const fontWeight = row.level == 1 ? '700' : '400';
  row.analytic = analytic;

  const toggleOpen = () => {
    setRowOpen(!rowOpen);
  };

  const handleRightClick = (event: any, row: any, key: string, level: number, columnLabel: string) => {
    event.preventDefault();
    setRightClickRow({ row, key, level, columnLabel });
    setRightClickAnchorEl(event.currentTarget);
  };

  const handleRightClickClose = () => {
    setRightClickAnchorEl(null);
  };

  const handleClickDetailsButton = () => {
    setRightClickAnchorEl(null);
    handleOpenNewDetailsModal(righClickRow);
  };

  const handleClickManualInputsButton = () => {
    setRightClickAnchorEl(null);
    handleOpenNewManualInputsModal(righClickRow);
  };

  //abrir e fechar os niveis individuais de acordo com o estado do nivel
  useEffect(() => {
    if (openLevels[level + 1] === false) {
      setRowOpen(false);
    } else {
      setRowOpen(true);
    }
  }, [openLevels]);

  return (
    <>
      <TableRow key={`row-${row.description}-${level}-${row?.children?.length}`}>
        <div style={{ backgroundColor: 'white', padding: 0, position: 'sticky', left: 0, width: '300px' }}>
          <TableCell sx={{ minWidth: '300px', position: 'sticky', left: 0, zIndex: 2 }} className="labelTableCell">
            <Stack direction="row" spacing={1} sx={{ paddingLeft: paddingValue, alignItems: 'center', zIndex: 2 }}>
              {row?.children?.length > 0 ? (
                <Icon onClick={() => toggleOpen()} component={Box} sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                  {rowOpen ? <ExpandLess /> : <ExpandMore />}
                </Icon>
              ) : (
                row.level !== 3 && (
                  <Icon component={Box} sx={{ display: 'flex', justifyContent: 'center', cursor: 'not-allowed', opacity: 0.2 }}>
                    <ExpandLess />
                  </Icon>
                )
              )}
              <Typography variant="subtitle2">{row.description}</Typography>
            </Stack>
          </TableCell>
        </div>
        {columns.map((column, index) => {
          return (
            row.totals &&
            column.visible && (
              <>
                {showInputsManual && (
                  <TableCell
                    onContextMenu={(event) => {
                      if (level === 3 && permissions_manual_input.hasReadPermission) {
                        handleRightClick(event, row, column.id, level, 'Acumulado Manual');
                      }
                    }}
                    key={`${column.id}-${index}-accumulated-manual`}
                    align="right"
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        transform: 'scale(1.2)',
                        transition: '0.3s'
                      }
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight }}>
                      {row.totals[column.id] ? row.totals[column.id].manual_entry_value : 0}
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  onContextMenu={(event) => handleRightClick(event, row, column.id, level, 'Valor')}
                  key={`${column.id}-${index}-value`}
                  align="center"
                  sx={{
                    borderRight: '0.5px solid #c7c7c7',
                    backgroundColor: row.totals[column.id]?.background_colors?.entry_value,
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].entry_value : 0}
                  </Typography>
                </TableCell>
              </>
            )
          );
        })}
      </TableRow>
      {((row.children?.length > 0 && rowOpen) || (levelOpen && rowOpen)) &&
        row.children.map((item: any, index: number) => (
          <TableRowRecursive
            key={`${item.description}-${index}-${level + 1}`}
            row={item}
            level={level + 1}
            analytic={level + 1 == 2 ? item.id : level + 1 > 2 ? analytic : null}
          />
        ))}

      {/* Popover que aparece quando clicado com o botao direito em alguma celula da tabela */}
      <Popover
        open={!!rightClickAnchorEl}
        anchorEl={rightClickAnchorEl}
        onClose={handleRightClickClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        sx={{ mt: 2 }}
      >
        <DialogContent sx={{ p: 0.5 }}>
          <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {righClickRow.level == 3 && (
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={handleClickManualInputsButton}
                startIcon={<FormatListBulletedIcon />}
              >
                Lançamentos Manuais
              </Button>
            )}
            <Button variant="text" color="primary" size="small" onClick={handleClickDetailsButton} startIcon={<FormatListBulletedIcon />}>
              Detalhamento
            </Button>
          </Stack>
        </DialogContent>
      </Popover>
    </>
  );
};
