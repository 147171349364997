import { CheckCircle, Close } from '@mui/icons-material';
import { Button, Popover, Stack, Tab, Tabs } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsBPFilter } from '../../../../../../../models/AnalyticsBP';
import { showSnackbarAlert } from '../../../../../../../store/slicers/snackbarAlert.slicer';
import { useAnalyticsBPTableContext } from '../../context/TableContext';
import { PeriodSelectorTabs } from './components/PeriodSelectorTabs';
import { TabPanel } from './components/TabPanel';

interface Props {
  anchorEl: HTMLElement | null;
  visible: boolean;
  onClose: () => void;
}

export const FilterPopoverRange = ({ anchorEl, visible, onClose }: Props) => {
  const periodRef = useRef(null);
  const retroPeriodRef = useRef(null);

  const { handleSetFilters } = useAnalyticsBPTableContext();

  const [currentRangeSelectorTab, setCurrentRangeSelectorTab] = useState(0);
  const [retroRangeSelectorTab, setRetroRangeSelectorTab] = useState(0);

  const dispatch = useDispatch();

  const [periodSelectorTab, setPeriodSelectorTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setPeriodSelectorTab(newValue);
  };

  const formValidations = (years: string[], period: [] | null, type: string | null, retroPeriod: [] | null, retroType: string | null) => {
    if (years.length == 0) {
      dispatch(showSnackbarAlert({ title: 'Erro!', message: 'Por favor, selecione um ano.', severity: 'error' }));
      return false;
    }

    if (!period && type != 'yearly') {
      dispatch(showSnackbarAlert({ title: 'Erro!', message: 'Por favor, preencha todas as datas do período atual.', severity: 'error' }));
      return false;
    }
    if (!retroPeriod && retroType != 'yearly') {
      dispatch(
        showSnackbarAlert({ title: 'Erro!', message: 'Por favor, preencha todas as datas do período retroativo.', severity: 'error' })
      );
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    let { type, period, years } = periodRef.current?.onSubmit();
    let { years: retroYears, type: retroType, period: retroPeriod } = retroPeriodRef.current?.onSubmit();

    if (!formValidations(years, period, type, retroPeriod, retroType)) {
      return;
    }

    let submitData: IAnalyticsBPFilter[] = [
      {
        year: years[0],
        type: type,
        period: period,
        period_retroactive: false
      }
    ];

    if (retroYears.length > 0) {
      retroYears.forEach((retroYear) => {
        submitData.push({
          year: retroYear,
          type: retroType,
          period: retroPeriod,
          period_retroactive: true
        });
      });
    }

    handleSetFilters(submitData);
    onClose();
  };

  const handleClear = () => {
    periodRef.current?.clear();
    retroPeriodRef.current?.clear();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      sx={{ mt: 2, display: visible ? 'flex' : 'none' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={periodSelectorTab}
          onChange={handleChangeTab}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
        >
          <Tab label="Período Atual" sx={{ display: 'flex', flex: 1 }} />
          <Tab label="Período Retroativo" sx={{ display: 'flex', flex: 1 }} />
        </Tabs>
        <TabPanel value={periodSelectorTab} index={0}>
          <PeriodSelectorTabs
            yearsSelectionQuantity={1}
            periodSelectorTab={currentRangeSelectorTab}
            setPeriodSelectorTab={setCurrentRangeSelectorTab}
            ref={periodRef}
          />
        </TabPanel>
        <TabPanel value={periodSelectorTab} index={1}>
          <PeriodSelectorTabs
            yearsSelectionQuantity={3}
            periodSelectorTab={retroRangeSelectorTab}
            setPeriodSelectorTab={setRetroRangeSelectorTab}
            ref={retroPeriodRef}
          />
        </TabPanel>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', p: 2 }} columnGap={1}>
          <Button variant="outlined" color="primary" onClick={handleClear} startIcon={<Close />}>
            Limpar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckCircle />}>
            Aplicar
          </Button>
        </Stack>
      </>
    </Popover>
  );
};
