import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Tab } from '@mui/material';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { DialogWithToggleFullscreen } from '../../../components/basics/DialogWithToggleFullscreen';
import { AnalyticsChart } from '../../../models/AnalyticsChart';
import { AnalyticsChartForm } from './tabs/AnalyticsChartForm';
import { AnalyticsChartFormulasForm } from './tabs/AnalyticsChartFormulasForm';

interface Props {
  editItem?: AnalyticsChart;
  onClose: () => void;
  setEditItem: (item: AnalyticsChart) => void;
}

export const AnalyticsChartFormDialog = ({ editItem, onClose, setEditItem }: Props) => {
  const [tabValue, setTabValue] = useState('1');
  const theme = useTheme();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };
  return (
    <DialogWithToggleFullscreen
      open={true}
      onClose={onClose}
      title={(editItem?.id ? 'Editar' : 'Novo') + ' Tipo de Análise Gráfica'}
      fullscreen={isFullscreen}
      fullWidth={true}
      maxWidth="md"
      toggleFullscreen={toggleFullscreen}
    >
      <Divider />
      <TabContext value={tabValue}>
        <Box sx={{ display: 'flex', flexDirection: 'row', borderColor: 'divider', height: '100%', padding: 0 }}>
          <TabList
            onChange={handleChangeTab}
            orientation="vertical"
            aria-label="charts tabs"
            sx={{ borderRight: 1, borderColor: 'divider', minWidth: '150px' }}
          >
            <Tab label="Cadastro" value="1" icon={<AppRegistrationIcon />} iconPosition="start" />
            <Tab label="Fórmulas" value="2" icon={<CalculateOutlinedIcon />} iconPosition="start" disabled={!editItem} />
          </TabList>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <TabPanel value="1">
              <AnalyticsChartForm editItem={editItem} onClose={onClose} setEditItem={setEditItem} />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 2, pb: 0 }}>
              <AnalyticsChartFormulasForm editItem={editItem} onClose={onClose} setEditItem={setEditItem} />
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </DialogWithToggleFullscreen>
  );
};
