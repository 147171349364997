import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { AnalyticsChart } from '../../../../../models/AnalyticsChart';
import { AnalyticsType } from '../../../../../models/AnalyticsType';
import { AnalyticsChartService } from '../../../../../services/AnalyticsChart.service';
import { AnalyticsTypeService } from '../../../../../services/AnalyticsType.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { AnalyticsChartScheme } from '../../../../../utils/forms/validations/formValidations';
import { findSelectedOption, setInputErrorsFromApi } from '../../../../../utils/utils';
import { useAnalyticsChartContext } from '../../../context/AnalyticsChartContext';

interface Props {
  editItem?: AnalyticsChart;
  onClose: () => void;
  setEditItem: (item: AnalyticsChart) => void;
}

const transformSelectOption = (analytics: AnalyticsType[]) => {
  return analytics.map((analytic: AnalyticsType) => ({
    id: analytic.id,
    name: `${analytic.code} - ${analytic.description}`
  }));
};
const periodOptions: SelectOption[] = [
  { id: 'ANNUAL', name: 'Anual' },
  { id: 'MONTHLY', name: 'Mensal' }
];

export const AnalyticsChartForm = ({ editItem, onClose, setEditItem }: Props) => {
  const dispatch = useDispatch();
  const { fetchAnalyticsChart } = useAnalyticsChartContext();
  const [analyticsType, setAnalyticsType] = useState<SelectOption[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const getDefaultValues = (): AnalyticsChart => {
    return {
      id: editItem?.id ?? null,
      title: editItem?.title ?? '',
      use_filters: editItem?.use_filters ?? false,
      retroactive_quantity: editItem?.retroactive_quantity ?? null,
      analytics_types: editItem?.analytics_types ? transformSelectOption(editItem.analytics_types) : [],
      current_period: editItem?.current_period ? findSelectedOption(periodOptions, editItem?.current_period) : null,
      retroactive_period: editItem?.retroactive_period ? findSelectedOption(periodOptions, editItem?.retroactive_period) : null
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<AnalyticsChart>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AnalyticsChartScheme),
    defaultValues: getDefaultValues()
  });

  const watchUseFilter = useWatch({
    control,
    name: 'use_filters',
    defaultValue: getValues('use_filters')
  });

  const fetchAnalyticsType = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsTypeService.getAll();
      if (response && checkResponseStatus(response)) {
        const result = response?.data?.results;
        setAnalyticsType(
          result.map((analyticsType: AnalyticsType) => ({
            id: analyticsType.id,
            name: `${analyticsType.code} - ${analyticsType.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const createOrUpdateAnalyticsChart: SubmitHandler<AnalyticsChart> = async (data: AnalyticsChart) => {
    dispatch(showSpinner());
    try {
      const payload = {
        id: editItem?.id,
        title: data.title,
        analytics_types: data.analytics_types.map((type) => type.id),
        use_filters: data.use_filters,
        current_period: data.current_period?.id,
        retroactive_period: data.retroactive_period?.id,
        retroactive_quantity: data.retroactive_quantity
      };

      let updatedItem;
      if (editItem?.id) {
        await AnalyticsChartService.update(payload);
        updatedItem = { ...editItem, ...payload };
      } else {
        const response = await AnalyticsChartService.create(payload);
        updatedItem = response?.data;
      }
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Tipo de Análise Gráfica atualizada com sucesso!' : 'Tipo de Análise gráfica criada com sucesso!',
          severity: 'success'
        })
      );

      setEditItem(updatedItem);

      fetchAnalyticsChart();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchAnalyticsType();

      setLoading(false);
      dispatch(hideSpinner());
    };

    loadData();
  }, []);

  return (
    <form onSubmit={handleSubmit(createOrUpdateAnalyticsChart)}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="title">Título</InputLabel>
            <ControlledTextInput name="title" placeholder="Título" control={control} errorMessage={errors.title?.message?.toString()} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="analytics_types">Análises</InputLabel>
            <ControlledComboBox
              name="analytics_types"
              control={control}
              errorMessage={errors.analytics_types?.message?.toString()}
              selectOptions={analyticsType}
              multiple={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1">Usa Filtros:</Typography>
            <ControlledCheckbox name="use_filters" label={watchUseFilter ? 'Sim' : 'Não'} control={control} />
          </Grid>
          {watchUseFilter && (
            <>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="current_period">Periodo Atual</InputLabel>
                <ControlledComboBox
                  name="current_period"
                  control={control}
                  errorMessage={errors.current_period?.id?.message?.toString()}
                  selectOptions={periodOptions}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="retroactive_period">Periodo Retroativo</InputLabel>
                <ControlledComboBox
                  name="retroactive_period"
                  control={control}
                  errorMessage={errors.retroactive_period?.id?.message?.toString()}
                  selectOptions={periodOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="retroactive_quantity">Quantidade Retroativa</InputLabel>
                <ControlledTextInput
                  name="retroactive_quantity"
                  placeholder="Quantidade Retroativa"
                  control={control}
                  errorMessage={errors.retroactive_quantity?.message?.toString()}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit">
          Salvar
        </Button>
      </DialogActions>
    </form>
  );
};
